.root {
  position: relative;
  width: 100%;
  overflow: auto;

  &.isStickyHeader {
    position: static;
    overflow: visible;

    table {
      position: relative;
    }

    th {
      z-index: 1;
    }
  }
}

.table {
  width: 100%;
  caption-side: bottom;
  font-size: 0.875rem;
  border-collapse: collapse;
}

.header {
  border-bottom: 1px solid;
}

.body {
  &:last-child {
    border: 0;
  }
}

.footer {
  border-top: 1px solid;
  background-color: rgba(0, 0, 0, 0.05);
  font-weight: 500;

  & > tr:last-child {
    border-bottom: 0;
  }
}

.row {
  border-bottom: 1px solid;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.head {
  height: 2.5rem;
  padding: 0 0.5rem;
  text-align: left;
  vertical-align: middle;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
}

.cell {
  padding: 0.35rem 0.5rem;
  vertical-align: middle;
  border: solid 1px #e4e4e7;
}

.caption {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.5);
}