.shapeDetailContainer {
    width: 100%;
    margin: 0 auto 2rem;
    display: grid;
    //grid-template-columns: repeat(5, 1fr);
    //auto fit
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-gap: 1rem;
    gap: 1rem;
    border: 2px solid rgb(226, 226, 226);
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    margin: 10px 0;
  }
  
  .detailItem {
    display: flex;
    padding: 8px 4px;
    border-bottom: 1px solid #e0e0e0;
    flex-direction: column;
    gap: 4px;
    font-size: 14px;
  }
  
  .label {
    font-weight: bold;
    color: #333;
  }
  
  .value {
    display: inline-block; /* Makes it behave like a block for wrapping */
    white-space: normal;
    word-break: break-word;
    color: #555;
  }

  .breakLine {
    min-width: 100%;
    margin: 10px 0;
    border: 2px solid rgb(207, 207, 207);
  }
  