.actionDropdown {
    display: block;
}

.actionForm {
    display: flex;
    flex-direction: column;
    // gap: 20px;
}

.formGroup {
    display: flex;
    margin-bottom: 15px;
    // width:100%;
    flex-direction: column;
    background:#f7f7f759;
    // border: 1px solid #eee;
    // padding: 20px;
    border-radius: 5px;
    // background: #fff;
    // border:1px solid #e4e4e4;
    // padding:20px;
    // border-radius:10px;
    label {
        color: #8d9094;
        font-size: 13px;
        font-weight: 500;
        padding-bottom:10px;
    }

    div {
        display: flex;
        width: 100%;
    }
}

.formGroup:last-child {
    border-bottom:1px solid #ccc !important;
    padding-bottom: 25px;
    border-radius: 0 !important;
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    button:first-child{
        background-color: #fff !important;
        color: #222;
        border: 1px solid #ccc;
    }
    button {
        margin: 0;
        border-radius: 6px;
        margin: 0;
        padding: 14px 20px !important;
    }
}

.batchActions {
    position: relative;
    min-width: 200px; /* Keep the same width as the search box */
    padding: 11px 10px; /* Match padding with searchBox */
    border-radius: 5px;
    appearance: none; /* Remove default arrow appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path fill="%230daaca" d="M7 10l5 5 5-5z"/></svg>') no-repeat;
    background-position: right 3px center;
    background-size: 20px 20px;
    border: 2px solid #0daaca;
    background-color: inherit;
    cursor: pointer;
    height: 42px; /* Adjust the height to match the height of the search box */
}


.selectInput {
    position: relative;
    min-width: 100%; /* Keep the same width as the search box */
    padding: 11px 10px; /* Match padding with searchBox */
    border-radius: 5px;
    appearance: none; /* Remove default arrow appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: 'Verdana';
    font-size: 13px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path fill="%230daaca" d="M7 10l5 5 5-5z"/></svg>') no-repeat;
    background-position: right 3px center;
    background-size: 20px 20px;
    // border: 2px solid #0daaca;
    border: 1px solid rgba(209, 209, 209, 0.7803921569);
    background-color: #fff !important;
    color: black !important;
    cursor: pointer;
    height: 42px; /* Adjust the height to match the height of the search box */
}

.textareaInput {
    position: relative;
    box-shadow: none;
    width: 150px !important;
    background-color: #fff !important;
    font-family: 'Verdana';
    padding: 11px 10px;
    border: 1px solid #d1d1d1c7 !important;
    background-color: inherit;
    border-radius: 5px;
    min-height: 40px; /* Explicitly set height to match */
    width: 100% !important;
    box-shadow: 0 2px 2px #eee;
}

.textInput {
    position: relative;
    box-shadow: none !important;
    // width: 150px !important;
    padding: 11px 10px;
    border: 1px solid rgba(209, 209, 209, 0.7803921569) !important;
    background-color: #fff !important;
    border-radius: 5px;
    min-height: 40px; /* Explicitly set height to match */
    color: black !important;
    font-family: 'Verdana';
    width: 100% !important;
    box-shadow: 0 2px 2px #eee !important;
}

.radioGroup {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    label {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 13px;
        color: #8d9094;
        font-weight: 500;
    }
}

.numberInputGroup {
    display: flex;

    .suffix {
        display: flex;
        align-items: center;
        padding: 11px 10px;
        border: 1px solid #d1d1d1c7;
        background-color: #fff;
        border-radius: 5px;
        min-height: 40px;
    }
}