@import '../scss/variables.scss';

.required {
  > label::after {
    content: '*';
    color: red;
  }
}

.row {
  display: grid;
  padding: 40px 55px 40px 10px;
  border-bottom: 1px solid #e5edef;
  min-height: 120px;
  position: relative;
  row-gap: 20px;
  column-gap: 20px;

  .subGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: column;
    column-gap: 20px;
    grid-template-areas: 'campaign adset ad';
    align-items: center;

    > div {
      min-height: 3rem;
    }

    > .campaign {
      grid-area: campaign;
      grid-row: auto;
      select {
        max-width: 170px;
      }
    }

    > .adset {
      grid-area: adset;
      grid-row: auto;
    }

    > .adset[title='Topics'] {
      margin-top: 20px;
      div {
        img:hover {
          cursor: pointer;
        }
      }
    }

    > .ad {
      grid-area: ad;
      grid-row: auto;
    }

    .selectBrand {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .logoApproved {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        margin-right: 5px;
        width: 24px;
        height: 24px;
      }
    }
    .currentValue {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 10px 0;
      height: 15px;
    }
  }

  &:hover {
    background-color: #f2fdff;
    border-bottom: 1px solid #0daaca;
  }

  & label {
    font-size: 1rem;
    font-weight: 300;
    margin: 0.5rem 0 0.2rem 0;
  }

  select {
    max-width: fit-content;
    height: 30px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    cursor: pointer;
    padding: 0px 5px;
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.5);
    border-bottom: 3px solid #9cb4b8;
    &:hover {
      border-bottom: 3px solid #0daaca;
    }
    &:focus-visible {
      outline: none;
    }

    &:disabled {
      color: grey;
    }
  }

  div {
    // display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // fix layout error on media gallery on adcreation
    // align-items: flex-start;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    input[type='button'] {
      margin: 0 2rem;
    }
  }
}

.rowDark {
  @extend .row;
  background-color: #2a2a2a;

  &:hover {
    background-color: #4a4a4a;
  }
}

.duplicate-row {
  @extend .row;
  &:hover {
    background-color: mix(mistyrose, indianred);
  }
}

.duplicate-row-dark {
  @extend .rowDark;

  background-color: mix(black, indianred);

  &:hover {
    background-color: mix(brown, indianred) !important;
  }
}

@media only screen and (max-width: 768px) {
  .row {
    grid-template-columns: 2fr 2fr;
    row-gap: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .row {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }
}

.manual-input {
  display: flex;
  flex-direction: column;
}

select {
  width: 250px;
}

.duplicateRow {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-image: url('/images/duplicate.svg');
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 35px;
  background-color: transparent;
  border-color: transparent;
  margin: 0.5rem;
  filter: invert(73%) sepia(1%) saturate(165%) hue-rotate(17deg) brightness(89%)
    contrast(87%);
  &:hover {
    filter: invert(40%) sepia(0%) saturate(2842%) hue-rotate(304deg)
      brightness(96%) contrast(100%);
  }
}

.buttonCopy {
  align-items: center;
  background-color: #50b27e;
  box-shadow: rgba(0, 0, 0, 0.01) 0 1px 3px 0;
  color: white;
  border: none;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  margin: 1rem 0.5rem 1rem;
  min-height: 2.2rem;
  padding: 0rem 1.5rem;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  border-radius: 3px;
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
    color: white;
    background-color: #359d66;
    transform: translateY(-1px);
  }
}

.resultRow {
  padding: 10px;
  margin-bottom: 1rem;

  a {
    color: $primaryColor;
  }

  &:hover {
    background-color: #f2fdff;
  }
  > div {
  }
}
.grid2 {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 20px;
  padding: 1rem;
}

.resultRowDark {
  @extend .resultRow;
  &:hover {
    background-color: #4a4a4a;
  }
}
.successfulAd {
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 4px #dddddd;
  border-radius: 0px;
}
