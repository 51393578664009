@import '../scss/variables';

h1 {
  font-size: 1.2rem;
  font-weight: 300;
  padding: 0.5rem 0rem;
  border-bottom: 2px solid $primaryColor;
}

h2 {
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem 0rem;
}

p {
  font-weight: 300;
  font-size: 1rem;
}
span {
  font-weight: 300;
}

/* Buttons */
.buttons,
input[type='button']:not(input[aria-label='Delete']):not(
    input[aria-label='Duplicate']
  ):not(.buttonDelete),
button {
  align-items: center;
  background-color: #0daaca;
  box-shadow: rgba(0, 0, 0, 0.01) 0 1px 3px 0;
  color: white;
  border: none;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  margin: 1rem 1rem 1rem 0;
  min-height: 2.2rem;
  padding: 0rem 1.5rem !important;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  border-radius: 3px;
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
    color: white;
    background-color: #1ab9d9;
    transform: translateY(-1px);
  }
  svg {
    position: relative;
    left: 5px;

    path {
      fill: white;
    }
  }
  &[disabled] {
      background-color: #d3d3d3;
      color: #a9a9a9;
      cursor: not-allowed !important;
      box-shadow: none;
      &:hover {
        background-color: #d3d3d3;
        color: #a9a9a9;
        box-shadow: none;
      }
  }
}

.buttonDelete {
  @extend .buttons;

  background-color: #a6575f;
  margin: 0 0 1rem 0;
  transition: all 250ms;

  &:hover {
    background-color: #7c4a51;
  }
}
