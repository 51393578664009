.dailyAdContainer{
    // .red {
    //     background: #e67c73;
    // }
    // .green {
    //     background: #56bb89;
    // }

    table {
        tbody {
            td {
                &:has(p.green) {
                    background: #56bb89;
                    color: #fff;
                }

                &:has(p.red) {
                    background: #e67c73;
                }

                p {
                    margin: 0;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;

                    span {
                        padding: 12px 15px;
                        display: block;
                    }
                }
            }
        }
    }


    .inputReport{
        width: 100%;
        display: flex;
        flex-direction: row;
        .checkbox{
            display: flex;
            align-items: center;
            margin-right: 4px;
            margin-top:10px;
            flex-direction: row;
            justify-content: space-between;
            input{
                margin-left:10px;
            }
            p{
                font-size: 14px;
                margin-top:0;
                margin-bottom:0;
            }
            label{
                font-size:13px;
            }
        }
    }
    .screenShotbtn{
        margin-top: 20px;
        margin-bottom: 0;
    }
    .accountSelector{
        border: 1px solid #ccc;
        width: 270px;
        padding: 8px 16px 26px 16px;
        border-radius: 4px;
        margin-bottom: 15px;
        h4{
            font-size:12px;
            margin-bottom: 0;
        }
        select{
            border-bottom: none;
            box-shadow: none;
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 3px;
            min-width: 235px;
        }
    .inputDatePicker{
        display: block;
        input{
            border-bottom: none;
            box-shadow: none;
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 3px;
            min-width: 252px;
        }
        h4{
            font-size:12px;
            margin-bottom: 0;
            padding-bottom: 10px;
        }
        select{
            border-bottom: none;
            box-shadow: none;
            width: 100vw;
            border: 1px solid #ccc;
            border-radius: 3px;
            min-width: 252px;
        }
    }
    .quoraAccountSelector{
           display: block;
            h4{
                font-size:12px;
                margin-bottom: 0;
                padding-bottom: 10px;
                margin-top:5px;
            }
            select{
                border-bottom: none;
                min-width: 235px;
                height:32px;
                box-shadow: none;
                width: 100%;
                min-width:263px;
                border: 1px solid #ccc;
                border-radius: 3px;
            }
        }
    }
    .campaignBids{
        background: transparent;
        border: none;
        box-shadow: none;
        width: 100%;
        font-size: 15px;
    }
    h3{
        font-weight: 600;
    }
    .inputDatePicker{
        width:240px;
       .dayPicker{
         width: fit-content;
        scale: 0.85;
        font-size: 17px;
        letter-spacing: 0.8px;
        background: #fff;
        border: 1px solid #e4e4e4;
        padding: 10px 25px;
        border-radius: 5px;
        box-shadow: 0px 3px 2px #eee;
        left: 5px;
        top: 390px;
        position: absolute;
        z-index: 2;
        }
        :hover{
            cursor: pointer;
        }
        input{
            width: 100%;
        }
    }
}

