.main {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 95%;
  max-width:1298px;
  margin: auto;
}

.mainDark {
  @extend .main;

  background-color: #2a2a2a;
  margin-bottom: -6rem;
}
