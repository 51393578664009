@use '../scss/variables';

.action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  position: relative;
}

.refreshData{
  background-color: rgb(12 239 76) !important;
  position: absolute;
  top: 17px;
  left: 15px;
  border-radius: 30px;
}

.dayPicker{
  display: block;
  scale: 0.85;
  position: absolute;
  background: #fff;
  border: 1px solid #eee;
  padding: 10px 20px;
  z-index: 3;
  top: 25px;
  left: -153px;
  border-radius: 7px;
}

.datePickerBtn {
  padding: 0 !important;
  margin: 0;
  width: 42px;
  height: 40px;
  background: #f6f6f6;
  border: 1px solid #dfdfdf;
  color: #141414;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background: #f6f6f6;
    color:#222;
  }

  &.isActive {
    background: #f6f6f6;
  }

  svg {
    fill: #333;
    margin-left:-10px;
  }
}

.noRefreshData{
  background-color: rgb(239, 1, 1) !important;
  position: absolute;
  top: 17px;
  left: 15px;
  border-radius: 30px;
}

.noResult{
  font-weight: 600;
  padding: 20px;
}

.reactSelectInput{
  width: 220px;
  svg{
    width:24px;
    height:24px;
  }
}

.actionWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.activeCheckbox{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  label {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin-left: .35rem;
  }

  input {
    background-color: #0daaca;
    border-color: #0daaca;
  }
}

.filterResult {
  font-size: 18px;
  color: #333;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 20px 0;
  .recordCount {
    font-weight: bold;
    color: #007bff;
  }
}

.additionalFilter{
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 15px;
}

.brandAccountSelector {
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  height: 42px;
  border-radius: 4px;
}

.appliedFilter {
  background: #0daaca;
  color: #fff;
  width: 128px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  height: 40px;
  margin-left: 12px;
  &:hover {
    cursor: pointer;
    width: 128px !important;
    transition: none;
  }
  button {
    color:white;
  }
}

.active {
  background: #f3f3f3;
  cursor: pointer;
  height: 40px;
  padding: 0 15px;
  border-radius: 4px;
}

.searchIcon {
  width: 38px;
  height: 38px;
}

.searchAd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter {
  display: flex;
  flex-direction: row;
  border: 1px solid #dfdfdf;
  height: 35px;
  border-radius: 5px;
  gap: 10px;
  background: rgb(246 246 246);
  align-items: center;
  max-width: fit-content;
  border-top: 0px;
  cursor: pointer;
  scale: 1.2;
  padding: 0px 20px;
  margin-left: 10px;

  &:hover {
    background: rgb(224,224,224);
    background: linear-gradient(180deg, rgba(224,224,224,1) 0%, rgba(255,255,255,1) 98%);
    cursor: pointer;
    // height: 40px;
    border-radius: 4px;
    border-bottom: 3px solid #0daaca;
    opacity: 0.9; /* Example fade in effect */
  }

  img {
    width: 18px;
    height: 18px;
    transition: all 0.3s ease;
  }
}

.filterAdd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 10px;
  height: 40px;
  padding-bottom: 5px;
}

.filterAddons {
  display: flex;
  flex-direction: row;
  align-items: center;
  border:1px solid #ededed;
  gap: 10px;
  justify-content: flex-start;
  padding: 0 15px;
  p {
    color: #222 !important;
    text-indent: 0 !important;
  }
  &:hover {
    background: #f3f3f3;
    cursor: pointer;
    padding: 0 15px;
    border-radius: 4px;
  }
  &:active {
    filter: brightness(80%);
    padding: 0 15px;
    border-radius: 8px;
  }
  img {
    width: 12px;
    height: 12px;
  }
}

.menu {
  // box-shadow: 0px 3px 4px 0px rgba(175, 175, 175, 0.29);
  background:#f7f7f7;
  // border: 1px solid #c1c1c1;
  border-radius: 5px;
  color: #222;
  border-top: 1px solid #ededed;
  display: grid;
  grid-template-columns: 1fr; /* Single-column structure */
  gap: 10px;
  max-height: 480px;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 5px;

  ul {
    list-style-type: none; /* Remove default bullet points */
    padding: 0;
    margin: 0;
  }

  .menuList {
    display: grid;
    padding: 10px;
    grid-template-columns: repeat(
      3,
      1fr
    ); /* 3 columns, change to 4 for 4 columns */
    grid-gap: 10px;
    margin-bottom: 20px; /* Space between each parent menu item */

    .subMenuItem {
      display: flex;
      align-items: center;
      gap: 10px; /* Space between checkbox and label */
      padding: 10px;
      border-bottom: 1px solid #ddd;
      //border-radius: 5px;
      //background-color: #f9f9f9;
      //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add some shadow */
      transition: transform 0.2s ease-in-out;
    }

    .subMenuItem:hover {
      transform: scale(1.05); /* Slight hover effect */
    }
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menuItem {
  position: relative;
  border-top: 1px solid #c1c1c1;
  padding: 5px 0;
  //margin-bottom: 5px;
}

.subMenu {
  box-shadow: 0px 3px 4px 0px #afafaf4a;
  position: absolute;
  top: 0;
  left: 100%;
  background: #fff;
  min-width: 150px;
  max-width: 250px;
  border: 1px solid #dedede;
  border-radius: 9px;
  color: #545454;
  padding: 15px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-20px);
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  white-space: nowrap;
  z-index: 1000;
}

.defaultCheckbox{
  background-color: transparent;
}

.menuItem:hover .subMenu {
  opacity: 1;
  visibility: visible;
  transform: translateX(0); /* Slide in from the right */
}

.checkbox {
  margin-right: 10px; /* Space between checkbox and label */
  vertical-align: middle; /* Aligns checkbox with text */
}

.subMenu {
  position: absolute;
  top: 0;
  left: 100%;
  background: #fff;
  min-width: 220px;
  max-width: 400px;
  max-height: 340px; /* Fixed height for scrolling */
  overflow-y: scroll; /* Forces scroll even if items don't overflow */
  border: 1px solid #dedede;
  border-radius: 9px;
  color: #222;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-20px);
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  white-space: normal; /* Allow line breaks */
  z-index: 1000;

  /* Removed grid styles to revert back to single column */
  display: block; /* Changed to block for single column layout */
}

.subMenuItem {
  padding: 10px;
  //border-top: 1px solid #555;
}

.subMenuItem:first-child {
  border-top: none;
  //display: none;
}

.chevronIcon {
  //margin-left: 100px;
  width: 14px;
  height: 14px;
  transition: transform 0.3s ease;
}

.listIcon {
  height: 20px;
  width: 20px;
}

.menuFilterIcons {
  height: 20px;
  width: 20px;
  &:hover {
    cursor: pointer;
  }
}

.menuIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.menuItem:hover .chevronIcon {
  transform: rotate(90deg); /* For chevron_right to rotate on hover */
}

.arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.menuItem:hover .chevronIcon {
  transform: rotate(90deg); /* For chevron_right to rotate when hovered */
  filter: invert(1);
}

button:hover .arrow {
  transform: translateY(-50%) rotate(90deg); /* Rotate when submenu is open */
  filter: invert(1);
}

.searchContainer {
  display: flex;
  input{
    box-shadow: none !important;
    top: 10px !important;
    left: 20px !important;
    border: 1px solid #ededed !important;
    width: 90% !important;
  }
}

.menuContainer {
  display: flex;
}

.searchInput {
  width: 95% !important;
  border: 0 !important;
  min-height: 50px;
  border-radius: 4px;
  font-size: 1rem;
  text-indent: 5px;

  &:hover {
    border-color: #0daaca; /* Maintain the same border color on hover */
  }

  &::placeholder {
    color: #a7a7a7; /* Placeholder color for consistency */
    font-size: 16px;
  }
}

.filterOverlay {
  height: auto;
  z-index: 999;
  left: 0;
  position: absolute;
  top: 70px;
  transform: translateY(-20px);
  transition: transform 0.5s ease, opacity 0.5s ease;

  &.fadeIn {
    opacity: 1;
    transform: translateY(0);
  }
  .filterInner {
    background: #fcfcfc;
    padding-bottom: 5px;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    box-shadow: 0 2px 11px 1px #f1f1f19e;
    .filterCondition {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 15px;
      p {
        color: #a7a7a7;
        //text-indent: 20px;
        font-size: 15px;
      }
      .link {
        color: #0daaca;
        text-indent: 5px;
        text-decoration: underline;
      }
    }
    h5 {
      margin-bottom: 0;
      text-indent: 20px;
      font-weight: 500;
    }
    h4 {
      text-indent: 20px;
    }
    .filterSelection {
      display: flex;
      height: 40px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
      padding: 0 20px;
      padding-top: 10px;
      p {
        color: #222;
      }
      input {
        font-size: 1rem;
          border-radius: 4px;
          background: #ffff;
          border: 1px solid #c3c3c3;
          padding: 4px 12px;
          width: 220px;
          box-shadow: none;
      }
      select {
        height: 30px;
        border-top: 0;
        min-width: 200px;
        border-right: 0px;
        border-left: 0px;
        font-size: 16px;
        cursor: pointer;
        padding: 0px 5px;
        box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.5);
        border-bottom: 3px solid #0daaca;
        &:hover {
          border-bottom: 3px solid #0daaca;
        }
      }
    }
  }
}

.overlaySpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.ShapeTable {

  hr {
    opacity: 0.5;
  }

  button {
    // background: none;
    border: none;
    margin: 2px;
    color: #222;
    cursor: pointer;
    padding: 10px;
    width: auto;
    text-align: left;
    font-size: 13px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    // &:hover {
    //   width: 98% !important;
    //   box-shadow: none !important;
    // }
  }

  button:hover .listIcon {
    filter: invert(1);
  }
}
.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  p{
    font-size: 14px !important;
    font-weight:400;
  }

}

.filterActive {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  ul {
    display: flex;
    gap: 10px;
    li {
      background: #28afc1;
      padding: 2px 10px;
      gap: 2px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 35px;
      border-radius: 3px;
      p,
      h5 {
        font-size: 12px !important;
        color: #fff;
      }
      img {
        margin-left: 4px;
        width: 14px;
        height: 14px;
        filter: invert(1);
      }
    }
  }
}

.quickFilterWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  h4{
    padding: 0;
    margin: 0;
    font-family: 'Arial';
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 34px;
  
      &:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
  
    input:checked + .slider {
      background-color: #0daaca;
    }
  
    input:checked + .slider:before {
      transform: translateX(14px);
    }
  }
}

.searchBtn,
.quickFilterBtn {
  padding: 0 !important;
  margin: 0;
  width: 42px;
  height: 40px;
  background: #f6f6f6;
  border: 1px solid #dfdfdf;
  color: variables.$textColor;
  border-radius: 5px;

  &:hover {
    background: #f6f6f6;
    color: variables.$textColor;
  }

  svg {
    position: static;
  }

  &.isActive {
    color: variables.$primaryColor;
    display: block;
  }
}

.batchActionGroup {
  display: flex;
  align-items: center;
  gap: 10px;
  height: auto;
  // border: 2px solid red;

  > p {
    margin: 0;
    margin-left: .5rem;
    font-size: 14px;
  }
}

.batchActions {
  position: relative;
  min-width: 200px; /* Keep the same width as the search box */
  padding: 11px 10px; /* Match padding with searchBox */
  border-radius: 5px;
  appearance: none; /* Remove default arrow appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path fill="%230daaca" d="M7 10l5 5 5-5z"/></svg>')
    no-repeat;
  background-position: right 3px center;
  background-size: 20px 20px;
  border: 1px solid #ccc;
  background-color: inherit;
  cursor: pointer;
  height: 42px; /* Adjust the height to match the height of the search box */
}

.searchBox {
  border: 1px solid #cccc;
  padding: 5px 10px;
  border-radius: 6px;
  background-color: #fff !important;
  display: none;
  input[type=text] {
    width: 100%;
    padding: 0;
    border-bottom: none !important;
    box-shadow: none !important;
  }
}
.slideInRight {
  animation: slideInRight 0.5s forwards;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

// .searchBox {
//   position: relative;
//   box-shadow: none;
//   width: 150px !important;
//   padding: 11px 10px;
//   border: 1px solid #ccc !important;
//   background-color: inherit;
//   border-radius: 5px;
//   min-height: 40px; /* Explicitly set height to match */

//   &:focus {
//     border: 1px solid #0daaca !important;
//   }
// }

.filterButton {
  justify-content: flex-start !important;
  width: fit-content !important;
}

.tableWrapper {
  display: flex;
  flex-direction: column;

  .deleteDataButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: auto;
    border-radius: 0;
    padding: none;
  }

  .tableBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    padding: 15px 0;
    //   height: 200px;

    .filterBar {
      display: flex;
      gap: 10px;
      align-items: center;
      width: 85%;
      height: 100%;
      // flex: 1;
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin: 10px 0;

  .pageInfo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;

    .goToPageForm {
      box-shadow: none;
      width: 50px;
      padding: 11px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }

  .selectPageRows {
    position: relative;
    min-width: 100px; /* Adjust width as needed */
    padding: 11px 10px;
    border-radius: 5px;
    appearance: none; /* Remove default arrow appearance */
    -webkit-appearance: none; /* Remove default arrow appearance for Safari */
    -moz-appearance: none; /* Remove default arrow appearance for Firefox */
    background: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2210%22 height%3D%2210%22 viewBox%3D%220 0 24 24%22%3E%3Cpath fill%3D%22%23ff0000%22 d%3D%22M7 10l5 5 5-5z%22/%3E%3C/svg%3E') no-repeat;
    background-position: right 3px center; /* Adjust right position to move arrow left */
    background-size: 30px 30px;
    border: 1px solid #ccc;
    background-color: inherit; /* Adjust as needed */
    cursor: pointer;
  }
}

.paginationButton,
.paginationButton:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 18px 10px;
  margin: 0;
  padding: 0 !important;
  min-width: 40px;

  svg {
    left: 0;

    path {
      fill: #999;
    }
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableContainer {
  width: 100%; /* Adjust as needed */
  max-height: 600px; /* Set the height you need */
  overflow: auto;

  &.expanded {
    max-height: 800px;
  }
}

.actionButton {
  background-color: transparent;
  padding: 0;
  justify-content: center;


  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
}

.table {
  width: 100%;
  table-layout: auto;
  // border-collapse: collapse;
  border-spacing: 0;
  border:1px solid #f0f0f0;
  td
  div {
    font-size: 15px !important;
  }
  //hide second extra column (from the left) (it's showing due to the checkbox on first column)
  th:nth-child(2),
  td:nth-child(2) {
    display: none;
  }
}
.th,
.td {
  border-bottom: 1px solid #ccc;
  color: #2a2a2a;
  padding: 8px; /* Adjust padding as needed */
  white-space: nowrap; /* Prevents text wrapping, ensuring the content fits in the cell */
  picture {
    position: relative;
    img:nth-child(1) {
      max-width: 40px;
    }
    img:nth-child(2) {
      display:none;
      position: fixed;
      max-width: 200px;
      top:10px;
      right:10px;
      @media(min-width: 1024px) {
        max-width: 280px;
      }
    }
    &:hover {
      img:nth-child(2) {
        display: block;
      }
    }
  }
}

.thDark,
.tdDark {
  border-bottom: 1px solid #d6d6d6;
  color: white;
  padding: 8px; /* Adjust padding as needed */
  white-space: nowrap;
  // other styles...
}

thead th {
  position: sticky;
  top: 0;
  background: white;
  white-space: normal !important;
}

.tbodyContainer {
  display: block;
  max-height: 350px; /* Adjust as needed */
  overflow-y: auto;
}

.tbodyContainer tbody {
  display: block;
}

.tbodyContainer tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tbodyContainer td {
  display: table-cell;
}

.th:first-child,
.td:first-child {
  text-align: center;
  vertical-align: middle;
}

.thDark:first-child,
.tdDark:first-child {
  text-align: center;
  vertical-align: middle;
}

//hide second extra column (from the left) (it's showing due to the checkbox on first column)
// th:nth-child(2),
// td:nth-child(2) {
//   display: none;
// }

// .th:last-child,
// .td:last-child
// {
//   width: 100px;
//   // display: flex;
//   // justify-content: center;
//   // align-items: center;
//   // gap: 10px;
// }

// .td:last-child {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 10px;
// }

// .th:first-child,
// .td:first-child {
//     width: 50px;
// }

.th {
  ::first-letter{
    text-transform: uppercase;
  }
  text-align: left;
  cursor: pointer;
  color: black;
  z-index: 7; //for vertical scrolling sticky header

  .canSort {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    // border: 1px solid red;
  }
}

.buttonContainer{
  display: flex;
  gap: 70px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right:40px;
  button{
    color:#fff;
    margin-right:20px;
    svg{
      width:20px;
      height:20px;
      margin-right: 10px;
    }
  }
}

.buttonAddFilter{
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: 15px;
}

.thDark {
  text-align: left;
  background-color: #2a2a2a;
  cursor: pointer;
  color: white;

  .canSort {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    svg {
      fill: white;
    }
  }
}

.tr {
  background-color: #f4f4f4;
  height: 40px;
  &:nth-child(odd) {
    background-color: #f9f9f9;
    //text-transform: capitalize;
  }

  &:hover {
    background-color: #f1f1f1;
  }
}


.trDark {
  background-color: #919191;
  height: 40px;
}

//this z-index for sticky cells
.th:first-child,
.thDark:first-child {
  z-index: 8;
}
.td:first-child {
  z-index: 6;
}

.th:last-child,
.thDark:last-child {
  z-index: 8;
}
.td:last-child {
  z-index: 6;
}

.stickyHeader {
  z-index: 8;
}

.stickyData {
  z-index: 6;
}

.stickyTh {
  z-index: 7;
}

.sticky {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  background: white; /* Or the background color of your table */
  // z-index: 1; /* Ensure it stays above other content */
}

.stickyLeft {
  // z-index: 3;
  left: 0;
}

.stickySecondRow {
  left: 46px;
  z-index: 2;
}

.stickyThirdRow {
  left: 137px;
  z-index: 2;
  > * {
    width: 126px;
    overflow:hidden;
    transition: width 0.5s;
    text-overflow: ellipsis;
    &:hover {
      transition: width 0.5s;
      width: auto;
    }
  }
}

.hoverflow {
  left: 137px;
  z-index: 2;
  > * {
    width: 126px;
    overflow:hidden;
    transition: width 0.5s;
    text-overflow: ellipsis;
    &:hover {
      transition: width 0.5s;
      width: auto;
    }
  }
}



.stickyRight {
  right: 0;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  background: white; /* Or the background color of your table */
  /* Apply z-index only to th elements */
  th {
    z-index: 999; /* Ensure it stays above other content */
  }

  button {
    margin: 0;
    text-align: center;
    width:100%;

    svg {
      left: 0;
      path {
        fill: #0daaca;
      }
    }
  }
}


.modalContainer {
  right: 0;
  min-height: 100vh;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  --dialog-animation-name: none;
  --dialog-position-left: auto;
  --dialog-transform: translate(0, -50%);
  --dialog-width: 300px;
  --ld-border-radius: 5px;
}

.modalHeader {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;

  h1 {
    font-size: 25px;
    font-weight: 500;
  }
}

.modalHeader h2 {
  margin: 0;
}

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  img {
    width: 80%;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .formSection {
      display: flex;
      flex-direction: column;
      gap: 8px;

      p {
        text-align: center;
      }

      .formFilter {
        border: 1px solid var(--ld-input-placeholder);
        --ld-input-border-radius: 5px;
      }
    }

    .filter {
      display: flex;
      flex-direction: column;
      gap: 10px;

      button {
        padding: 8px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        width: 100%;
      }
    }
    // justify-content: center;

    // .input {
    //   width: 100%;
    // }
  }
}

.modalFooter {
  display: flex;
  text-align: right;
}

.buttonFilter {
  width: 100%;
}

.modalFooter button {
  padding: 8px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
}

.modalFooter button:hover {
  background-color: #0056b3;
}

.badge {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  color: #007bff; // Text color
  border: 1px solid #007bff;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 12px;
  // margin-right: 8px;

  .badgeText {
    margin-right: 4px;
  }

  .badgeCloseButton {
    border: none;
    background: none;
    color: inherit;
    font-size: inherit;
    cursor: pointer;
    margin-left: 4px;

    &:hover {
      color: #ff0000; // Change color on hover
    }
  }
}

/* Hide the default checkbox */
input[type='checkbox'] {
  position: relative;
  appearance: none;
  width: 22px;
  height: 22px;
  background-color: #eee;
  border: 2px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  vertical-align: middle;
}

/* Custom checkmark */
input[type='checkbox']:after {
  content: '';
  position: absolute;
  display: none;
  left: 5px;
  // top: 2px;
  width: 6px;
  height: 12px;
  border: solid #0daaca;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
input[type='checkbox']:checked {
  background-color: white;
  border-color: #0daaca;
}

input[type='checkbox']:checked:after {
  display: block;
}

/* Hover effect */
input[type='checkbox']:hover {
  background-color: #ccc;
}

.formGroup {
  display: flex;
  gap: 20px;
  align-items: center;

  label {
    color: black;
    width: 30%;
    // border: 2px solid red;
  }
}

.statusGuidance {
  position: relative;
  min-width: 200px; /* Keep the same width as the search box */
  padding: 11px 10px; /* Match padding with searchBox */
  border-radius: 5px;
  appearance: none; /* Remove default arrow appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path fill="%230daaca" d="M7 10l5 5 5-5z"/></svg>')
    no-repeat;
  background-position: right 3px center;
  background-size: 20px 20px;
  border: 1px solid #ccc;
  background-color: inherit;
  cursor: pointer;
  height: 42px; /* Adjust the height to match the height of the search box */
}

.modalForm {
  position: relative;
  box-shadow: none;
  width: 200px !important;
  padding: 0 10px;
  border: 1px solid #ccc !important;
  background-color: inherit;
  border-radius: 5px;
  min-height: 36px; /* Explicitly set height to match */
}

.skeletonCell {
  background-color: #e0e0e0; /* Placeholder background */
  border-radius: 4px;
  animation: pulse 1.5s infinite;
  width: 100%;
  height: 20px;
}

.filterBtn {
  height: 40px;
  margin: 0 !important;
  background: #f6f6f6;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  color: variables.$textColor;

  &:hover {
    background-color: #f6f6f6;
    color: variables.$textColor;
  }

  svg {
    position: static;
    margin-right: 10px;
  }
}

.filterBadgeRow {
  display: flex;
  gap: .35rem;
}

.tableBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.tableBottomEnd {
    justify-content: flex-end;
  }
}

/* Skeleton animation */
@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f7f7f7;
  }
  100% {
    background-color: #e0e0e0;
  }
}
