@use '../scss/variables';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.info {
    text-align: left;
    display: block;
}

.btn {
    margin: 0 !important;
}

.lastExecuted {
    color: #71717a;
    font-size: 14px;
    font-style: italic;

    span {
        color: #333;
        display: inline-block;
        font-weight: 500;
    }
}

.tableBlock {
    width: 100%;
    border-top: solid 1px #e4e4e7;

    h5 {
        margin-bottom: .5rem;
    }
}

.tableWrapper {
    height: 50vh;
    overflow-y: auto;

    &.withoutScroll {
        height: auto;
        overflow: visible;
    }
}

.cellCheckbox {
    width: 30px;

    input[type="checkbox"]:disabled {
        cursor: not-allowed;
    }
}

.cellAlignLeft {
    text-align: left;
}

.cellFull {
    width: 100%;
}

.cellAction {
    min-width: 40px;
    text-align: center !important;
}

.automation2 {
    // max-width: 700px;
    width: 100%;
}

.automation1 {
    // max-width: 700px;
    width: 100%;
}

.headerContent {
    text-align: center;
    max-width: 700px;
    margin: auto;

    h3 {
        margin-bottom: .5rem;
    }

    p {
        &:first-of-type {
            margin-top: 0;
        }
    }
}

.tableScroll {
    overflow-y: auto;
    max-height: 50vh;
}

button.infoCircle {
    margin: 0 !important;
    padding: 0 !important;
    color: variables.$primaryColor;
    background-color: transparent;
    top: 2px;
    left: 3px;
    min-height: auto;

    &:hover {
        box-shadow: none;
    }

    svg {
      position: static;

      path {
        fill: currentColor;
      }
    }
}