@use "../scss/variables";

.Overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 20;
}

.Content {
  background-color: #fff;
  border-radius: variables.$borderRadius;
  box-shadow:  variables.$defaultShadow;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  max-height: 85vh;
  padding: 25px;
  outline: none;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 50;

  * {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
}

.Title {
  margin: 0;
  font-size: 17px;
	font-weight: 500;
  padding: 0;
}

.Description {
  margin-bottom: 15px;
	font-size: 15px;
	line-height: 1.5;
  color: #65636d;
}

.Header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: variables.$textColor;
}

.Footer {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}