@import '../scss/variables';

.root {
    font-size: 14px;
    height: 30px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    cursor: pointer;
    padding: 0px 5px;
    box-shadow: $inputShadow;
    border-bottom: 3px solid #9cb4b8;

    &:hover {
      border-bottom: 3px solid $primaryColor;
    }
    
    &:focus-visible {
        outline: none;
    }
}