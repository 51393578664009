.genericSearchCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // font-family: monospace;
  padding: 20px;
  flex-wrap: nowrap;
  min-width: 10vw;
  font-size: 14px;
  position: relative;
  border: 1px solid #eee;
  background-color: #e0e0e0;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;

  img {
    width: 64px;
    height: 64px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    //transform: scale(1.1); /* Increase scale on hover */
    z-index: 1; /* Ensure hovered item overlays others */
    cursor: pointer;
  }
  //:not(:hover) {
  //  opacity: 0.8; /* Reduce opacity of non-hovered items */
  //}

  svg {
    padding-top: 14px;
    width: 42px;
    height: 42px;
  }
}

.lastChecked {
  font-size: 1rem;
  display: flex;
  padding-top: 10px;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    justify-content: center;
  }

  .isLoading {
    margin-top: -15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      font-size: 14px;
      font-family: monospace;
    }
  }

  span {
    font-size: 13px;
    font-family: monospace;
    display: flex;
    align-self: center;
    text-transform: capitalize;
  }
}

.baseIndicator {
  display: block;
  > h2 {
    padding: 0;
  }

  > h2::before {
    // content: '';
    position: absolute;

    display: inline-block;

    width: 12px;
    height: 12px;

    border-radius: 50%;

    top: 5px;
    left: 5px;

    border: 3px inset;
  }

  > h2::after {
    position: absolute;

    font-size: 0.7rem;

    padding-top: 6px;
    padding-left: 27px;

    top: 0;
    left: 0;
  }
}

.loading {
  @extend .baseIndicator;

  > h2::before {
    background-color: blue;
    border-color: darkblue;
  }

  > h2::after {
    //   content: 'Loading';
  }
}

.noData {
  @extend .baseIndicator;
  background-color: #f9f9f9a8;
  padding: 20px;
  text-align: center;

  h2 {
    font-size: 16px;
    font-family: monospace;
  }

  > h2::before {
    background-color: darkslategray;
    border-color: grey;
  }
}

.notFound {
  @extend .baseIndicator;

  > h2::before {
    background-color: red;
    border-color: indianred;
  }

  > h2::after {
    // content: 'Not Found';
  }
}

.paused {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @extend .baseIndicator;

  > h2::before {
    background-color: gold;
    border-color: goldenrod;
  }

  > h2::after {
    // content: 'Paused';
  }
}

.delivering {
  @extend .baseIndicator;

  > h2::before {
    background-color: lime;
    border-color: green;
  }

  > h2::after {
    // content: 'Delivering';
  }
}
