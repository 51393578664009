.searchbar {
  width: 100%;
}

.searchContainer {
  position: relative;

  &:focus-within > .optionContainer {
    display: flex !important;
  }
}

.optionContainer {
  background-color: #fff;
  width: fit-content;
  position: absolute;
  top: calc(8ch - 0.4rem);
  flex-direction: column;
  overflow-x: hidden;
  max-height: 50vh;
  z-index: 1000;

  width: 70%;

  display: none !important;
}

.optionContainerDark {
  @extend .optionContainer;
  background-color: #2a2a2a;
}

.option {
  padding: 5px;
  border-bottom: 1px solid #2a2a2a;

  width: 100%;
}

.optionDark {
  @extend .option;
  border-bottom: 1px solid #e0e0e0;
}

.selectable {
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: #c9c9c9;
  }
}

.selectableDark {
  @extend .selectable;
  &:hover,
  &:focus {
    background-color: #4a4a4a;
  }
}
