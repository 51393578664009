@import '../scss/variables';

.Content {
    border-radius: 6px;
    // padding: 1rem;
    // width: 260px;
    padding:20px;
    border:1px solid #dadada !important;
    background: #fff;
    box-shadow:
            hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
            hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    z-index: 50;

  &[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }

  &[data-state='open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }

  &[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }

  &[data-state='open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }
}

.Trigger {
    background-color: transparent;
}

.Arrow {
  fill: #fff;
}

.Close {
  font-family: inherit;
  border-radius: 100%;
	height: 25px;
	width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $textColorGrey;
  position: absolute;
  top: 10px;
  right: 10px;
  // hard style because conflict from Dashboard.scss
  margin: 0 !important;
  padding: 0 !important;
  min-height: auto;
  background-color: transparent;

  &:hover {
    color: $textColorGrey;
    background-color: transparent;
    box-shadow: none;
  }

  svg {
    position: static;
    height: 20px;
    width: 20px;

    // hard style because conflict from Dashboard.scss
    path {
      fill: currentColor;
    }
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}