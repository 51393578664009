.secondary {
  color: #0daaca;
  background-color:white;
  &:hover {
    background-color: #fafafa;
    color: white;
  }
}
.object {
  margin:1rem 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: baseline;
  > div {
    background-color: #fcfcfc;
    padding: 1rem;
    border:1px solid #e2e2e2;
    max-width: 90%;
    width: 100%;
    &:hover {
      background-color: #f9f9f9;
    }
  }
}