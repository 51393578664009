.deleteButton {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-image: url('/images/delete.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
    background-color: transparent;
    border-color: transparent;
    margin: .5rem;
    filter: invert(73%) sepia(1%) saturate(165%) hue-rotate(17deg) brightness(89%) contrast(87%);

    &:hover {
        filter: invert(40%) sepia(9%) saturate(2842%) hue-rotate(304deg) brightness(96%) contrast(83%);
    }

    &:active {
        filter: invert(40%) sepia(9%) saturate(2842%) hue-rotate(304deg) brightness(96%) contrast(83%);
    }

}