.tableContainer {
  display: block;
  // padding: 20px;
  // margin: 0 auto;
  // max-width: 1200px;
  .accountId {
    box-shadow: 1px 5px 10px 0px #ebebeb;
    padding-bottom: 20px;
    border: 1px solid #d8d8d8;
    display: flex;
    // padding: 20px;
    text-indent: 15px;
    flex-direction: column;
    border-radius: 3px;
    h1 {
      font-size: 28px;
      text-transform: uppercase;
      margin-top: 0;
      padding: 20px 0 0 0;
      text-indent: 25px;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    .identifier {
      display: flex;
      flex-direction: column;
      // margin: 20px;
      // align-items: center;
      label {
        font-size: 12px;
        margin:10px 15px 15px 10px;
        font-weight: 600;
      }
      p {
        background: #f4f4f4;
        margin: 0;
        font-size: 18px;
        padding: 10px;
        margin-top: 0;
      }
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin-top: 20px;

  tbody {
    td {
      position: relative;
    }
  }
}

.table th,
.table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 15px;
}

.table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr > td:first-child {
  font-weight: bold;
}

.table tr:hover {
  background-color: #f1f1f1;
}
