@import '../scss/variables';

// time range
.timeRange {
  position: relative;

  label {
    margin-right: 0.5rem;
  }
}

.selectWrapper {
  display: flex;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-left:5px;
    padding: 0 1rem !important;
    background-color: transparent;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;

    svg {
      position: static;

      path {
        fill: $primaryColor;
      }
    }
  }
}

.timeRangeSelect {
  &.outline {
    height: 42px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
    box-shadow: $inputShadow;
    
    &:focus-visible {
      border: 1px solid $primaryColor;
      outline: none;
    }
  }
  &.dateRangeWidth {
    min-width: 350px;
  }

  &.singleDateWidth {
    min-width: 300px;
  }
}


.timeRangeCalendar {
  position: absolute;
  right: 0;
  z-index: 50;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #edebeb;
  top: 50px;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, .15);

  > * {
    font-size: 14px;
  }

  // temporary hard code to restyle the calendar
  // since there is an issue style.css is missing in node_modules
  // that's why we import the style from CDN
  button[class='rdp-day_button'],
  td[class='rdp-day'] {
    width: 36px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 !important;
    box-shadow: none;
  }

  td:nth-child(2) {
    display: table-cell;
  }

  button[class='rdp-day_button'] {
    border-radius: 50% !important;
    transition: none;

    &:hover {
      background-color: rgba(13, 170, 202, 1);
      color: #fff;
      transform: none;
    }

    &:disabled {
      background-color: transparent;
    }
  }

  button[class='rdp-button_previous'],
  button[class='rdp-button_next'] {
    padding: 0 !important; 
    margin: 0 !important;

    &:hover {
      background-color: transparent;
      width: var(--rdp-nav_button-width) !important;
      box-shadow: none;
    }
  }

  svg[class='rdp-chevron'] {
    fill: $primaryColor;
    position: static;
  }
}

.timeRangeAction {
  display: flex;
  justify-content: space-between;
  gap: .5rem;
  padding-top: .25rem;

  button {
    margin: 0 !important;
    width: 100%;

    svg {
      position: static;

      path {
        fill: currentColor;
      }
    }
  }

  .cancelBtn {
    background-color: transparent;
    color: $textColorGrey;
    border: solid 1px #ccc;

    &:hover {
      color: $primaryColor;
      border-color: $primaryColor;
      background-color: transparent;
      box-shadow: none;
    }
  }
}
