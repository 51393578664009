.root {
  display: inline-flex;
  width: 100%;
}

.helperText {
  font-size: 0.8rem;
  color: var(--lincd-magiclogin-input-text-color);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.form {
  width: 100%;
  max-width: 18rem;

  .input {
    font-family: var(--lincd-magiclogin-font-family);
    font-size: var(--lincd-magiclogin-font-size);
    font-weight: 400;
    line-height: 1.5;
    border-radius: var(--lincd-magiclogin-input-border-radius);
    background-color: var(--lincd-magiclogin-input-background-color);
    color: var(--lincd-magiclogin-input-text-color);
    border: var(--lincd-magiclogin-input-border);
    box-shadow: var(--lincd-magiclogin-input-box-shadow);
    width: 100%;

    &:hover {
      border: var(--lincd-magiclogin-input-border-hover);
    }

    &:focus {
      border: var(--lincd-magiclogin-input-border-focus);
      box-shadow: var(--lincd-magiclogin-input-box-shadow);
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  }
}

.control {
  position: relative;

  width: fit-content;
}

.control_error {
  @extend .control;

  div:first-child {
    box-shadow: 0 3px 3px tomato;
  }
}

.control_success {
  @extend .control;

  div:first-child {
    box-shadow: 0 3px 3px darkseagreen;
  }
}

.end {
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--lincd-magiclogin-button-background-color);
  color: var(--lincd-magiclogin-button-icon-color);
  cursor: pointer;
  padding: 0;
}

.spinner {
  path {
    transform-origin: center;
    animation: spinner 0.75s infinite linear;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
