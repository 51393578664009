@import 'scss/variables.scss';

body {
  margin: 0;
}

input[name='email'] {
  margin-left: 50px;
  font-size: 15px;
}
button {
  //width: 120px;
  //height: 32px;
}


.App {
  display: block;
  color: $textColor;

  min-height: 100vh;
}

.createAccount {
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Adjust this width as needed */
  .formrow {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    label {
      flex: 1;
      margin-right: 10px;
    }

    input[type='text'] {
      flex: 2;
    }

    input[type='button'],
    input[type='submit'] {
      flex: none;
      margin-right: 10px;
    }
  }
}

.AppDark {
  @extend .App;

  background-color: #2a2a2a;
  color: white;

  select,
  input:not([type='button']):not([type='submit']) {
    background-color: #2a2a2a;
    color: white;
  }
  input[name='email'] {
    margin-left: 50px;
    font-size: 15px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #2a2a2a inset;
    -webkit-text-fill-color: white;
    color: white;
    caret-color: white;
  }
}

input[type='search'],
input[type='url'],
input[type='text'] {
  height: 30px;
  padding: 0px 5px;
  width: 50%;
  border: 0;
  box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.5);
  border-bottom: 3px solid #9cb4b8;

  &:hover {
    border-bottom: 3px solid #0daaca;
  }
  &:focus-visible {
    outline: none;
  }
}

a {
    color: #0daaca;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
// @media (prefers-color-scheme: dark) {
// * {
//   // Generate a dark theme for me:
//   background-color: #1a1a1a;
//   color: #ffffff;
// }

// $primaryColor: #b19cd9;
// $secondaryColor: #a7d7c5;
// $accentColor: #f7b267;

// .App {
//   display: block;
//   color: $primaryColor;
// }

// *.dark {
//   background-color: #2a2a2a;
//   color: $primaryColor;
// }
