.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color:#fbfbfb;
    padding: 20px 25px;
    border-radius: 8px;
    width: 440px;
    max-width: 90%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    animation: modalFadeIn 0.3s ease-in-out;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    h2 {
        color: black;
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        border-bottom: 1px solid #cececeee;
        width: 100%;
        padding-bottom: 15px;
    }
  }
  
  .closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    font-size: 1.5rem;
    padding: 0px 10px !important;
    scale: 0.8;
    position: absolute;
    right:-15px;
    cursor: pointer;
    color: #8d9094;
    width: 30px;
    height: 8px;
    margin: 0;
    top:-10px;

    &:hover {
       border-radius: 100%;
    }
  }
  
  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // margin-top: 10px;
    // margin-bottom: 10px;
  }
  
  .modalFooter {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .modalCloseButton {
    background-color: white;
    // color: white;
    // padding: 8px 16px;
    border: 2px solid #0daaca;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: #0daaca;
  }

  .modalSubmitButton {
    background-color: #007bff;
    // color: white;
    // padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
  }
  
  
  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  