/* Light Mode */
.MediaMixContainer {
  display: block;

  .mediaInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .totalTable {
    color: #000;
  }

  .headline {
    display: block;
  }

  td {
    p {
      font-size: 13.5px;
    }
  }

  .skeleton {
    display: inline-block;
    min-height: 0;
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    width: auto;
    color: transparent;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -150%;
      height: 100%;
      width: 150%;
      background: linear-gradient(
        to right,
        transparent 0%,
        rgba(255, 255, 255, 0.4) 50%,
        transparent 100%
      );
      animation: loading 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }

  @keyframes loading {
    0% {
      left: -150%;
    }
    50% {
      left: 100%;
    }
    100% {
      left: 100%;
    }
  }

  /* Container for the multi-select dropdown */
  .multiSelectDropdown {
    position: relative;
    width: 250px;
    font-family: Arial, sans-serif;
  }

  .multiSelectDropdown h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1rem;
    color: #333;
  }

  /* Header that displays the selected options */
  .dropdownHeader {
    max-width: 250px;
font-size: 14px;
height: 30px;
border-top: 0px;
border-right: 0px;
border-left: 0px;
cursor: pointer;
padding: 0px 5px;
box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.5);
border-bottom: 3px solid #9cb4b8;
margin: 10px 0px;
  }

  /* Dropdown menu that contains all options */
  .dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 10;
    max-height: 200px; /* Optional: set a max height if there are many options */
    overflow-y: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
  }

  /* Style for each option in the dropdown menu */
  .dropdownOption {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    font-size: 0.9rem;
    color: #333;
    cursor: pointer;
  }

  /* Checkbox style inside each option */
  .dropdownOption input[type='checkbox'] {
    margin-right: 8px;
  }

  /* Highlight background on hover */
  .dropdownOption:hover {
    background-color: #f0f0f0;
  }

  /* Scrollbar styling (optional) */
  .dropdownMenu::-webkit-scrollbar {
    width: 6px;
  }

  .dropdownMenu::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
  }

  .dropdownMenu::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }

  .buttonGeneric {
    // background: #0daaca;
    margin-top: 20px;
    width: auto !important;
    font-weight: 500;
    font-size: 14px !important;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    position: relative;
    overflow: hidden;

    .spinnerImg {
      scale: 0.7;
    }

    .active {
      border-color: #fff !important;
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .mediaMixData {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 14px;
    text-align: left;

    th,
    td {
      padding: 12px 15px;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f4f4f4;
      font-weight: bold;
    }

    tr {
      &:nth-child(even) {
        background-color: #f9f9f9;
      }

      &:hover {
        background-color: #f1f1f1;
      }
    }

    caption {
      caption-side: bottom;
      padding: 10px;
      font-size: 14px;
      color: #777;
    }
  }
}

/* Dark Mode */
.MediaMixContainer {
  display: block;

  .darkMode {
    .totalTable {
      color: #fff;
    }

    .skeleton {
      background-color: #333;

      &::before {
        background: linear-gradient(
          to right,
          transparent 0%,
          rgba(255, 255, 255, 0.2) 50%,
          transparent 100%
        );
      }
    }

    .buttonGeneric {
      background: #005f73;
      color: #fff;

      .active {
        border-color: #fff !important;
      }
    }

    .mediaMixData {
      th {
        background-color: #444;
        color: #fff;
      }

      td {
        background-color: #333;
        color: #fff;
      }

      tr {
        &:nth-child(even) {
          background-color: #444;
        }

        &:hover {
          background-color: #555;
        }
      }

      caption {
        color: #aaa;
      }
    }
  }
}
