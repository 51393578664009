$primaryColor: #0daaca;
$primaryColorDark: #09839c;
$secondaryColor: #0daaca;
$textColorGrey: #5f6d7e;
$textColor: #141414;
$textColorSmall: #6d6d6d;
$borderRadius: 8px;
$gray200: #e5e5e5;
$gray50: #f3f3f3;
$boxPadding: 8px;
$errorColor: #ff0000;
$warningColor: #ffa500;
$infoColor: #0000ff;
$successColor: #008000;
$backgroundColor: #ffffff;
$grayColor: #cccccc;

// breakpoints
$breakpoint-xs: 320px;
$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1300px;

// shadow
$defaultShadow: 0 5px 10px -10px rgba(0, 0, 0, 0.5);
$inputShadow: $defaultShadow;