@import '../scss/variables';

.root {
  min-width: 600px;
  
  * {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
  }

  // hard reset button styles from dashboard.scss
  button {
    margin: 0 !important;

    svg {
      position: static;

      path {
        fill: currentColor;
      }
    }
  }
}

.fieldRow {
  display: flex;
  gap: .75rem;
  margin-bottom: .75rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.fieldSelect,
.fieldInput {
  min-width: 200px;
}

.fieldInput[type='text'] {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 42px;
  padding: 0 .65rem;

  &:hover,
  &:focus {
    border-color: #b3b3b3;
    border: 1px solid #ccc;
  }
}

.separator {
  height: 1px;
  width: 100%;
  background-color: #ccc;
  margin: 1rem 0;
}

.fieldAction {
  display: flex;
  justify-content: flex-end;
  gap: .75rem;
}

.addBtn {
  background-color: transparent;
  color: $textColorGrey;
  border: solid 1px #ccc;

  &:hover {
    color: $primaryColor;
    border-color: $primaryColor;
    background-color: transparent;
    box-shadow: none;
  }
}

.removeBtn {
  background-color: transparent;
  color: $textColorGrey;
  min-width: 40px;
  padding: 0 !important;

  &:hover {
    background-color: transparent;
    color: #333;
    box-shadow: none;
  }
}


.badge {
  background-color: $primaryColor;
  border-radius: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: .5rem .65rem;
  gap: 0.25rem;

  .operator,
  .value {
    font-weight: 500;
  }

  // hard reset button styles from dashboard.scss
  button {
    margin: 0 0 0 .25rem !important;
    padding: 0 !important;
    background-color: transparent;
    color: #fff;
    font-size: 13px;
    min-height: auto;

    &:hover {
      background-color: transparent;
      color: #fff;
      box-shadow: none;
    }

    svg {
      position: static;

      path {
        fill: currentColor;
      }
    }
  }
}