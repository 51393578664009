.createAccount {
  label {
    min-width: 200px;
    display: inline-block;
    line-height: 2rem;
    vertical-align: middle;
  }

  input[type="text"] {
    min-width:200px;
  }
  input[type="checkbox"] {
    margin-left:1rem;
  }
}