.isLoading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  width: auto;
  zoom: 0.86;
  p {
    margin-left: 10px;
    color: #0daaca;
    font-weight: 600;
  }
}

.uploadedImages {
  img {
    width: 150px;
    border: 1px solid #0daaca;
    height: 150px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
    padding: 5px;
  }
}

.MediaUploader.animationStart {
  animation: fadeIn 0.5s ease forwards;
}

.MediaUploader.animationStop {
  animation: fadeOut 0.5s ease forwards;
}

.MediaUploader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0; /* Start with opacity 0 */
  transform: scale(0.95);
  z-index: 999;

  .MediaContent {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 600px;
    height: 600px;
    border-radius: 10px 10px 0 0;
    background-color: #fff;
    margin: 0 auto;
    margin-top: 100px;
    overflow: hidden; /* Ensures no overflow from this container */

    .closeButton {
      position: absolute;
      right: 20px;
      top: 13px;
    }

    .MediaHeader {
      background: #eee;
      flex-shrink: 0;
      // width: 100%;

      h1 {
        color: #333;
        margin: 0;
        padding: 15px;
        // width: 100%;
      }
    }
    .MediaBody {
      gap: 12px;
      flex-grow: 1;
      padding: 20px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: 100%;
      // width: 100%;
     
      .spinner{
        position: absolute;
        top: 300px;
        bottom: 0;
        margin: 0 auto;
        left: 280px;
        right: 0;
      }

      .mediaLoading {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        text-align: center;
        position: relative;
        top: 40%;
      }

      :hover {
        background: #eee;
        border-radius: 5px;
      }
      .MediaList {
        padding: 0 10px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        .linkCopied {
          font-size: 10px;
        }

        button {
          border-radius: 5px;
          width: 44px;
          font-size: 13px;
          height: 40px;
          border: 1px solid #a8a8a891;

          img:hover,
          p:hover {
            background: transparent;
          }
        }

        button:hover {
          background: #0daaca;
          color: #222;
          border: 1px solid #006b81;
        }

        .MediaSubContent {
          display: flex;
          align-content: center;
          flex-direction: row;
          gap: 15px;
          flex: 2;

          .MediaPicture {
            display: flex;
            gap: 12px;
            flex-direction: row !important;
          }

          img {
            width: 85px;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
          }
        }
        .MediaLabel {
          display: flex;
          flex-direction: column;

          .typeCheck {
            position: relative;
            right: -190px;
            margin-top: -17px;
            background-color: rgb(13, 170, 202);
            border-radius: 2px;
            font-size: 11px;
            width: 45px;
            padding: 5px;
            text-align: center;
            color: #fff;
          }

          .MediaFile {
            margin-top: 0;
          }

          p {
            color: #222;
            font-size: 13px;
            font-weight: 500;
            text-transform: capitalize;
            margin-bottom: 5px;
          }

          .MediaMime {
            color: #888;
            font-size: 10px;
            margin-top: 0;
            text-transform: none !important;
            font-weight: 400;
          }
        }
      }
    }
  }
  .MediaFooter {
    background: #e5e5e5;
    margin: 0 auto;
    height: 55px;
    gap: 300px;
    border-top: 1px solid #d2d2d2;
    align-items: center;
    justify-content: space-around;
    display: flex;
    width: 600px;
    border-radius: 0 0 10px 10px;

    .buttonInsert {
      background-color: #0daaca;
      border: 1px solid #02778f;
      color: #fff;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        background-color: #92c4ce;
      }
    }

    button {
      margin-right: 10px;
    }

    div {
      display: flex;
      align-items: center;
    }
    p {
      color: #222;
    }

    .MediaUploadMore {
      background: #222;
      color: #fff;
      border-radius: 100px;
      width: 115px;
      height: 36px;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.95);
    }
  }
}

.inputFile {
  margin-bottom: 20px;
}

.input {
  margin-top: 5px;

  label {
    margin-bottom: 5px;
  }
  input[type='text'] {
    width: clamp(5rem, 70%, 15rem);
    width: max(5rem, min(70%, 15rem));
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    height: 30px;
    padding: 0px 5px;
    border-bottom: 3px solid #9cb4b8;
    box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 0.5);

    &:hover {
      border-bottom: 3px solid #0daaca;
    }
  }
  input {
    width: auto;

    input {
      margin: 0 10px;
    }
  }
}

.uploadResult {
  position: absolute;
  background: rgb(0 0 0 / 38%);
  border: 1px solid #999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;

  .uploadContainer.animationStart {
    animation: fadeIn 0.5s ease forwards;
  }

  .uploadContainer.animationStop {
    animation: fadeOut 0.5s ease forwards;
  }
  .uploadContainer {
    background-color: rgba(42, 42, 42, 0.97);
    width: 90%;
    margin: 0 auto;
    position: relative;
    top: 200px;
    padding: 30px;
    padding-top: 10px;
    opacity: 0; /* Start with opacity 0 */
    transform: scale(0.95);

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: scale(0.95);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }

    @keyframes fadeOut {
      from {
        opacity: 1;
        transform: scale(1);
      }
      to {
        opacity: 0;
        transform: scale(0.95);
      }
    }

    .inputSelected {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      input,
      p {
        margin: 0;
        margin-right: 5px;
      }
    }

    .closeButton {
      position: absolute;
      right: 30px;
      top: 20px;
    }

    .selectMedia {
      margin-top: 50px;
      height: 35px;
    }

    .uploadInner {
      display: flex;
      flex-wrap: wrap;
    }

    .inputContainer {
      padding: 10px;
      width: 150px;
      background-color: #fff;
      margin-right: 15px;
      margin-bottom: 15px;

      .hidden {
        display: none !important;
      }
      .info {
        zoom: 0.9;
        display: flex;
        flex-direction: column;

        p {
          font-size: 12px;
          margin-top: 5px;
          color: #222;
          margin-bottom: 0px;
        }
        margin-bottom: 5px;
      }
      label {
        color: #222;
        font-size: 13px;
        font-weight: 600;
      }

      input {
        width: auto;
      }
    }
    .inputContainer:hover {
      label,
      p {
        color: #222;
      }
      background-color: #0daaca;
    }

    .cardSelected {
      padding: 10px;
      width: 150px;
      background-color: #fff;
      margin-right: 15px;
      margin-bottom: 15px;
      height: auto;
      transition: height 0.3s;
      // &:hover {
      //   height:
      // }
      label {
        color: #222;
        font-size: 13px;
        font-weight: 600;
      }

      input {
        width: auto;
      }

      label,
      p {
        color: #222;
      }
      .info {
        zoom: 0.9;
        display: flex;
        flex-direction: column;

        p {
          font-size: 12px;
          margin-top: 5px;
          color: #222;
          margin-bottom: 0px;
        }
        margin-bottom: 5px;
      }
      background-color: #0daaca;
    }
  }
}

.imgError {
  margin-right: 10px;
}

.validationError {
  zoom: 0.85;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  width: auto;
  border-radius: 5px;
  color: #0daaca;
  h4 {
    margin: 0;
    margin: 5px;
  }
  p {
    color: #ff2e2e;
    margin: 5px;
  }
}
