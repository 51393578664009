@import '../scss/variables';

.results {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  //min-width: 50%;
}

.container {
  display: flex;

  width: 100%;
  height: fit-content;

  gap: 20px;
}

.resultsSummary {
  padding: 20px 0 5px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;

  span {
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 20px;
    font-family: monospace;
    font-size: 18px;
  }
  // border-bottom: 2px solid $primaryColor;

  span:not(:first-child)::before {
    color: $primaryColor;
    content: '|';
    font-weight: bolder;
    padding: 0 1ex;
  }
}

.resultsPanelContainer {
  top: 10vh;
  position: sticky;

  height: fit-content;

  > svg {
    position: absolute;
    right: 0;

    width: 24px;
    height: 24px;

    cursor: pointer;

    z-index: 1;

    &:hover {
      fill: indianred;
    }
  }
}
