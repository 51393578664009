.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  border-radius: 5vw;

  padding: 30px;
  margin: 50px 0;
  outline: solid black 2px;
}

.manual-input {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
}

.datePickerCustom{
  display: flex;
  flex-direction: row;
  align-items: center;
  .datePickerInput{
    margin-left:10px;
    display: flex;
    align-items: center;
    gap: 10px;
    input{
      font-size: 14px;
    }
  }
}

.filterHeading{
  margin-bottom:5px;
}

select {
  max-width: fit-content;
}
.dropdown {
  max-width: fit-content;
        height: 30px;
        border-top: 0px;
        font-size: 14px !important;
        border-right: 0px;
        border-left: 0px;
        cursor: pointer;
        padding: 0px 5px;
        box-shadow: 0 5px 10px -10px rgba(0,0,0,.5);
        border-bottom: 3px solid #9cb4b8;
        margin: 10px 0px;
        &:hover {
          border-bottom: 3px solid #0DAACA;
          }
        &:focus-visible {
            outline: none;
        }
}

.dropdownCustom {
        max-width: 250px;
        // height: 40px;
        font-size:14px;
        height: 30px;
        border-top: 0px;
        border-right: 0px;
        border-left: 0px;
        cursor: pointer;
        padding: 0px 5px;
        box-shadow: 0 5px 10px -10px rgba(0,0,0,.5);
        border-bottom: 3px solid #9cb4b8;
        margin: 10px 0px;
        &:hover {
          border-bottom: 3px solid #0DAACA;
          }
        &:focus-visible {
            outline: none;
        }
}
