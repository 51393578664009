.red {
  background-color: #e67c73;
}

.lightRed {
  background-color: hsl(2, 63%, 87%);
}

.green {
  background-color: #56bb89;
  color: white;
}

.yellow {
  background-color: #fed766;
}
