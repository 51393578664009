.dailySalesContainer{
    .red{
        color: red;
    }
    .green{
        color: green;
    }
    .inputReport{
        width: 100%;
        display: flex;
        flex-direction: row;
        .checkbox{
            display: flex;
            align-items: center;
            margin-right: 4px;
            margin-top:10px;
            flex-direction: row;
            justify-content: space-between;
            input{
                margin-left:10px;
            }
            p{
                font-size: 14px;
                margin-top:0;
                margin-bottom:0;
            }
            label{
                font-size:13px;
            }
        }
    }
    .accountSelector{
        border: 1px solid #ccc;
        width: 270px;
        padding: 8px 16px 26px 16px;
        border-radius: 4px;
        margin-bottom: 15px;
        .buttonSelector{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            button{
                margin-top: 20px;
                margin-bottom: 0;
                flex:1;
            }
            .screenShotbtn{
                margin-top: 20px;
                margin-bottom: 0;
            }
            .csvButton:disabled{
                background: #d3d3d3;
                color:#a9a9a9;
            }
            .csvButton{
                background-color: #fff;
                border: 1px solid #ccc;
                color: #0daaca;
                margin-right: 0;
            }
        }
        h4{
            font-size:12px;
            margin-bottom: 0;
        }
        select{
            border-bottom: none;
            box-shadow: none;
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 3px;
            min-width: 235px;
        }
    .inputDatePicker{
        display: block;
        input{
            border-bottom: none;
            box-shadow: none;
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 3px;
            min-width: 252px;
        }
        h4{
            font-size:12px;
            margin-bottom: 0;
            padding-bottom: 10px;
        }
        select{
            border-bottom: none;
            box-shadow: none;
            width: 100vw;
            border: 1px solid #ccc;
            border-radius: 3px;
            min-width: 252px;
        }
    }
    .quoraAccountSelector{
           display: block;
            h4{
                font-size:12px;
                margin-bottom: 0;
                padding-bottom: 10px;
                margin-top:5px;
            }
            select{
                border-bottom: none;
                min-width: 235px;
                height:32px;
                box-shadow: none;
                width: 100%;
                min-width:263px;
                border: 1px solid #ccc;
                border-radius: 3px;
            }
        }
    }
    .campaignBids{
        background: transparent;
        border: none;
        box-shadow: none;
        width: 100%;
        font-size: 15px;
    }
    h3{
        font-weight: 600;
    }
    .inputDatePicker{
        width:240px;
       .dayPicker{
         .rdp-range_end {
                background-color: #0daaca !important;
         }
        width: fit-content;
        scale: 0.85;
        font-size: 17px;
        letter-spacing: 0.8px;
        background: #fff;
        border: 1px solid #e4e4e4;
        padding: 10px 25px;
        border-radius: 5px;
        box-shadow: 0px 3px 2px #eee;
        left: 5px;
        top: 390px;
        position: absolute;
        z-index: 2;
        }
        :hover{
            cursor: pointer;
        }
        input{
            width: 100%;
        }
    }
}

