// Style for Quicklaunch component


.quickLaunchContainer {
  .headerTemplates{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    .searchTemplates{
      border: 1px solid #e4e4e4;
      // width: 100%;
      display: flex;
      flex:1;
      box-shadow: none;
      border-radius:3px;
      padding: 5px 0;
      margin-bottom:10px;
      input{
        box-shadow: none;
        border:none;
        font-size: 15px !important;
      }
    }
    .headerInnerTemplates{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    > div:first-child{
      flex:1;
      font-weight: 600;
      border:none;
    }
    .excludedSearch{
      // border: 1px solid #e8e8e8;
      // margin-left: 10px;
      scale: 0.9;
      padding: 5px;
      border-radius: 5px;
      align-items: center;
      display: flex;
      img{
        width: 28px;
        height: 28px;
        // margin-right: 5px;
      }
    }
    
    .excludedSearch:hover{
      cursor: pointer;
      background-color: #eeeeee;
    }

    .excludedItems{
      display: flex;
      scale: 0.9;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      // border: 1px solid #e8e8e8;
      // padding: 0 10px;
      padding: 3px;
      border-radius: 5px;
      .counter{
        font-size: 12px;
        margin-left: 5px;
        font-weight: 600;
        background: #0daaca;
        color: white;
        padding: 5px 8px;
        border-radius: 70px;
      }

      p{
        font-size: 12px;
      }

      img{
        width:32px;
        height:32px;
      }
    }
    .excludedItems:hover{
      cursor: pointer;
      background-color: #eeeeee;
    }
  }
}
  .tableTemplates{
    // line-height: 35px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid #e7e7e7;
    background: #f9f9f9;
    // gap:30px;
    align-items: center;
    // padding: 10px 8px;
    font-size: 15px;

    .toggleValue{
      flex:2 !important;
    }
    
    div{
       flex:1 !important;
       text-align: left !important;
       font-weight: 600 !important;
       border:none !important;
     }
  }
  select {
    max-width: fit-content;
    height: 30px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    cursor: pointer;
    padding: 0px 5px;
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.5);
    border-bottom: 3px solid #9cb4b8;
  }
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  :hover.initialReport,
  :hover.mediaUploader,
  :hover.variationContent,
  :hover.constraintOptions {
    // background-color: #4a4a4a;
  }

  .buttonCTA {
    border: 1px solid #eee;
    color: #222;
  }

  .landingPageInput {
    margin: 5px 40px 20px 40px;
  }

  .inputMulti {
    display: block;
    padding-top: 20px;
    label{
      clear: both;
      width: 100%;
      display: block;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .select {
      width: 100%;
      padding: 5px 12px;
      font-size: 16px;
      line-height: 1.5;
      color: #333;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;
      transition: border-color 0.3s, box-shadow 0.3s;
    
      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }
    
      &:hover {
        border-color: #888;
      }
    }
    
    // label{}
    //   //::after {
    //   //  content: '*';
    //   //  color: red;
    //   //}
    // }
    div {
      label {
        margin-bottom: 5px;
      }
    }
    .flexColumn {
      display: flex;
      flex-direction: row;
      padding: 10px;
      input{
        margin-right:5px;
      }
      div {
        margin-bottom: 20px;
      }
    }
  }

  :hover.initialReport {
    width: 105%;
  }

  .previewAds {
    margin-left: 20px;
  }

  h2 {
    margin-bottom: 0;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .createButton {
      width: 15%;
      margin: 0 auto;
      margin-top: 2rem;
      height: 42px;
      margin-bottom: 4rem;
    }
  }

  .inputMulti {
    display: block;
    div {
      label {
        margin-bottom: 5px;
      }
    }
    .flexColumn {
      display: flex;
      flex-direction: row;
      padding: 10px;
      padding-top:15px;
      padding-bottom:0;
    }
  }

  * > .formInput {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;



    label {
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 500;
      // color: #fff;
    }

    .plusContent {
      display: flex;
      flex-direction: row;

      input,
      textarea,
      select {
        min-width: 350px;
      }

      .bodyInput {
        background-color: white;
      }

      .bodyInputDark {
        @extend .bodyInput;

        background-color: #2a2a2a;
        color: white;
      }

      .addPlus {
        width: auto !important;
        margin-left: 15px;
      }
    }

    input {
      height: 30px;
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      padding: 0px 5px;
      box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.5);
      border-bottom: 3px solid #9cb4b8;
      width: 50%;
      min-width: 250px;
    }
    textarea {
      border-top: 0px;
      width: 50%;
      min-width: 250px;
      border-right: 0px;
      border-left: 0px;
      box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.5);
      border-bottom: 3px solid #9cb4b8;
    }
    input:hover,
    textarea:hover {
      border-bottom: 3px solid #0daaca;
    }
  }

  .initialReport {
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: row;
    background-color: inherit !important;
    gap: 220px;
    background-color: transparent !important;

    .sectionLeft {
      display: block;
    }
    .sectionRight {
      display: block;
    }
  }
  .mediaUploader {
    // padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: row;

    .mediaAdset {
      ul > {
        li {
          ::before {
            width: 20px;
            height: 20px;
          }
          font-size: 14px;
        }
      }
    }

    .mediaSelected {
      display: block;
      // margin-left: 225px;
      .logo {
        border-radius: 100%;
        width: 72px;
        height: 72px;
      }
    }

    .mediaLoad {
      display: block;

      main {
        background-color: transparent;
        margin-top: 2rem;
      }
    }

    header,
    nav {
      display: none;
    }
    main {
      width: 100%;
    }
  }
  .variationContent {
    padding: 10px 20px 20px 20px;
    justify-content: space-between;
    flex-direction: row;
  }
  .constraintOptions {
    // padding: 10px 20px 20px 20px;
    display: flex;
    gap: 20px;

    h3{
      margin-bottom:0;
    }

    > div:first-child {
      flex: 1;
      // max-width: fit-content;
      border: 1px solid #e2e2e2;
      padding: 20px;
      border-radius: 5px;
      // margin-right: 2rem;
    }
    > div:last-child {
      flex: 2;
      // max-width: fit-content;
      border: 1px solid #e2e2e2;
      padding: 20px;
      border-radius: 5px;
      .notesTemplate{
        font-size: 10px;
      }
      // margin-right: 2rem;
    }
  }

  .mediaContainer {
    overflow: hidden;
    display: flex;
    min-width: 300px;
    margin-right: 15px;
    border-radius: 5px;
    flex-direction: column;
    transition: transform 0.5s ease;

    img {
      height: 200px;
    }

    button {
      border: 1px solid #181818;
      margin: 0 auto;
      width: 90%;
      margin-bottom: 15px;
      border-radius: 20px;
      height: 42px;
      background: #e6e6e6;
    }
  }

  .mediaContainerDark {
    @extend .mediaContainer;

    overflow: hidden;
    display: flex;
    min-width: 300px;
    margin-right: 15px;
    border-radius: 5px;
    flex-direction: column;
    transition: transform 0.5s ease;
    background: #313131;
  }

  .headingNavigation {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: row;
  }

  .mediaList {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    padding: 10px;
    zoom: 0.9;
    overflow: hidden;

    .showMoreButton {
      text-align: center;
    }

    .adContain {
      display: block;
      padding: 20px;
      padding-top: 0;
      padding-bottom: 0;

      .promoted {
        font-size: 12px;
      }

      .logoSponsor {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 10px;
          p {
            margin: 0;
            padding: 0;
            font-size: 13px;
          }
          h4 {
            margin: 0;
            padding: 0;
            font-size: 16px;
            margin-bottom: 2px;
          }
        }

        img {
          width: 42px;
          border: 1px solid #222;
          margin: 0;
          border-radius: 100px;
          height: 42px;
          background: #fff;
          object-fit: contain;
        }
      }
    }

    .adsContent {
      line-height: 20px;
      margin-top: 15px;
      p,
      h2 {
        padding: 0;
        margin: 8px 0;
      }
      h2 {
        font-size: 16px;
        margin-bottom: 5px;
      }
      p {
        font-size: 14px;
        margin-top: 0;
      }
    }

    h2 {
      font-weight: 600;
    }
    img {
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 100%;
      height: 225px;
      object-fit: cover;
      margin-top: 0;
    }
  }

  .selectBrand {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .logoApproved {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-right: 5px;
      width: 24px;
      height: 24px;
    }
  }
}
.message {
  text-align: center;
  padding: 1rem;
  background-color: #eefcff;
  margin-top: 1rem;
  /*center spinner*/
  > div {
    display: block;
    margin: 1rem auto;
  }
}
.messageDark {
  text-align: center;
  padding: 1rem;
  // background-color: #eefcff;
  margin-top: 1rem;
  /*center spinner*/
  > div {
    display: block;
    margin: 1rem auto;
  }
}

.emailInput {
  text-align: center;
  input {
    padding: 3px 5px;
  }
}
.labelSmall {
  font-size: 11px;
  color: #b6b6b6;
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 15px;
}

.testDataBlock {
  display: flex;
  align-items: center;
  gap: 1rem;
}