@import '../scss/variables';

.main {
  width: 196px;
  height: 196px;

  border-bottom: 2px solid $primaryColor;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  svg {
    width: 84px;
    height: 84px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
    text-decoration: none;
  }
  text-decoration: none;
  color: inherit;
}
