.tableTemplates{
  line-height: 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // gap: 45px;
  justify-content: space-between;
  text-indent: 12px;
  border-bottom: 1px solid #e7e7e7;
  background: #ffffff;
  font-size: 15px;
  .toggleValue{
    flex:2 !important;
  }
  div{
    flex:1 !important;
    border:none !important;
    text-align: left !important;
    padding:0 !important;
  }
}

.tableTemplates:nth-of-type(even){
  background: #f9f9f9;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  margin: 0;
  padding: 5px;
}

.tableTemplates:nth-of-type(odd){
  background: #fff;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  margin: 0;
  padding: 5px;
}

.dropdown {
  margin: 0.5rem 2rem 0 0;
  padding: 3px 6px;
  cursor: pointer;
  &:hover {
    background: #f7f7f8;
  }
  input[type='button'] {
    align-items: center;
    background-color: #0daaca;
    box-shadow: rgba(0, 0, 0, 0.01) 0 1px 3px 0;
    color: white;
    border: none;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    line-height: 1;
    margin: 1rem 1rem 1rem 0;
    min-height: 2.2rem;
    padding: 0rem 1.5rem;
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
    border-radius: 3px;

    &:hover {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
      color: white;
      background-color: #1ab9d9;
      transform: translateY(-1px);
    }

    svg {
      position: relative;
      left: 5px;

      path {
        fill: white;
      }
    }
  }

  & summary {
    cursor: pointer;

    background-color: white;

    position: sticky;
    top: 0;
    z-index: 1;

    transition: all 250ms ease;
  }

  &[open] summary {
    margin-bottom: 5px;
    opacity: 1;
  }
}

.dropdownDark {
  @extend .dropdown;

  & summary {
    background-color: #2a2a2a;
  }
  &:hover{
    background-color: #2a2a2a;
  }

}

.table {
  // table {
  border-collapse: collapse;
  // }
  
  td {
    border: 3px #0daaca solid;
    padding: 1rem;
  }
}