@import '../scss/variables';

.Root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 500px;
}

.List {
  flex-shrink: 0;
  display: flex;
  background-color: #f5f6f7;
  padding: .5rem;
  border-radius: 3px;
  // border-bottom: 1px solid #e4e4e7;
}

.Trigger {
  font-family: inherit;
  background-color: transparent;
  padding: 0 20px;
  flex: 1; // make full width
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1;
  color: $textColor;
  user-select: none;
  
  // reset button style
  margin: 0 !important;
  padding: .5rem 1rem !important;
  border-radius: 3px;

  &:hover {
    color: $primaryColor; 
    background-color: transparent;
    box-shadow: none;
  }

  &[data-state='active'] {
    background-color: #fff;
    // color: $primaryColor; 
    // box-shadow: inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor;
  }
}

.Content {
  flex-grow: 1;
  padding: 1rem;
  background-color: white;
  // border-bottom-left-radius: 6px;
  // border-bottom-right-radius: 6px;
  outline: none;
}