.templateItem {
  margin: 2rem 0;
  padding: 1rem;

  input[type='button'] {
    margin-top: 1rem;
    margin-left: 0;
  }

  &:hover {
    background-color: #f2fdff;
  }
}

.spinner {
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin-right: 15px;
  }
}

.templateItemDark {
  @extend .templateItem;

  &:hover {
    background-color: #4a4a4a;
  }
}
.selected {
  padding:1rem;
  margin:1rem 0;
}
