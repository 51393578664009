.toggleButton {
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: center;
  text-align: center;

  :hover {
    cursor: pointer;
  }

  &.noTransition .switch {
    transition: none;
  }
}

.toggle {
  display: none;
}

.switch {
  position: relative;
  border: 2px solid #2f6087;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  transition: background-color 0.3s;

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
  }
}

.toggle:checked + .switch {
  background-color: #2196f3;
}

.toggle:checked + .switch::before {
  transform: translateX(20px);
}
