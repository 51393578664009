.AccountCard {
  display: block;
  margin: 5% 0;
  padding: 0 16px 16px 16px;
  position: relative;

  &:hover {
    background-color: #f2fdff;
  }

  h1 {
    text-align: center;
  }

  > input {
    top: 0;
  }
  .offers {
    position:relative;
    right:0px;
    margin:1rem;
    width: 100%;
    max-width: 350px;
    div {
      padding:3px;
      position:relative;
      &:hover {
        background-color: #f2fdff;
      }
      > input[aria-label="Delete"] {
        position:absolute;
        right:0px;
        top:0px;
        height:1.2rem;
        cursor:pointer;
      }
    }
  }

}

.AccountCardDark {
  @extend .AccountCard;

  &:hover {
    background-color: #4a4a4a !important;
  }
}

.DeleteConfirmation {
}
