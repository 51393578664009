.header {
  display: flex;
  width: 100%;
  height: 80px;
  padding: 5px;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  text-wrap: nowrap;
  margin-top: 1rem;
}

.headerDark {
  @extend .header;

  img {
    filter: hue-rotate(180deg) invert(1);
  }

  background-color: #2a2a2a;
  color: #b19cd9;
}

.menu {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: repeat(6, 0fr);
  grid-gap: 1rem;
  
  a:hover{
    text-decoration: none;
  }

  align-items: center;
  justify-items: center;

  svg:hover {
    color: #0daaca !important;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: #000;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    padding: 0.5rem;
    transition: all 0.3s ease-in-out;
    border-bottom: 2px solid transparent;
    &:hover {
      color: #086679;
      border-bottom: 2px solid #0daaca;
      text-decoration: none;
    }
  }
}

.menuDark {
  @extend .menu;
  background-color: #2a2a2a;

  a {
    color: #b19cd9;
    &:hover {
      color: #0daaca;
    }
  }
}

.logo {
  width: 200px;
  margin: auto 0px;
}
