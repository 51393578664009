.timeWindow {
  flex-direction: row !important;
  align-items: center !important;
  margin-top: 1rem;
  gap: 0.5rem;

  > input {
    width: 2rem !important;
  }
}
