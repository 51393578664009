@import '../pages/Quicklaunch.scss';

.Dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: max-content;
  width: 100%;

  .required {
    > label::after {
      content: '*';
      color: red;
    }
  }

  &.checkbox {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    min-height: fit-content !important;
  }

  .constraintOptions {
    padding: 10px 20px 20px 20px;
    display: flex;

    .inputMulti {
      div {
        display: block !important;
      }
    }
    > div {
      max-width: fit-content;
      margin-right: 2rem;
    }
  }

  // style for input checkbox
  input[type='checkbox'],
  input[type='radio'] {
    width: 20px;
    height: 15px !important;
    margin: 0;
    cursor: pointer;
    border: 1px solid #9cb4b8;
    border-radius: 5px;
    box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 0.5);

    &:hover {
      border: 1px solid #0daaca;
    }

    &:checked {
      background-color: #0daaca;
    }
  }

  input:not([type='checkbox']) {
    // width: clamp(5rem, 70%, 15rem);
    // width: max(5rem, min(70%, 15rem));
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    height: 30px;
    padding: 0px 5px;
    border-bottom: 3px solid #9cb4b8;
    box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 0.5);

    &:hover {
      border-bottom: 3px solid #0daaca;
    }
  }

  select {
    max-width: fit-content;
    height: 30px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    cursor: pointer;
    padding: 0px 5px;
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.5);
    border-bottom: 3px solid #9cb4b8;

    &:hover {
      border-bottom: 3px solid #0daaca;
    }

    &:focus-visible {
      outline: none;
    }
  }

  :disabled {
    cursor: not-allowed;
  }
}
